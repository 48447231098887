const OurTestimonials=()=>{
    return(
        <>
           <section class="tj-testimonial-section">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="tj-heading-area">
                  <h2 class="title"><span><sup>05</sup></span>Buyers Trajectory</h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="testimonial-content-area">
                  <div class="testimonial-item">
                    <div class="testimonial-quote">
                      <img src="assets/images/icons/quote.svg" alt="Icon" />
                    </div>
                    <div class="desc">
                      <h3>Path to the Perfect Property</h3>
                      <p className='text-light'>
                      At Feng Shui, we offer two seamless routes to your dream property. The first route
is to browse our website, where you can explore all the properties we offer. Our
easy-to-use filter system allows you to refine your search until you find your perfect
property.
Navigating the home-buying process can be daunting, but were here to provide all the
help you need. We offer detailed discussions about the houses we have available and
assist you with securing a mortgage if necessary. We will also explain all the costs
associated with buying a home, ensuring you have a clear understanding of the financial
aspects. Our goal is to provide a relaxed and worry-free experience as you search for
your new home. Feel free to explore our listings to see the variety of properties we offer.
Once found, and once you've contacted us, we will guide you through the entire process,
ensuring a smooth and stress-free experience.
                      </p>
                    </div>
                    <div class="testimonial-auother">
                      <div class="auother-images">
                        <img src="assets/images/service/service-2.jpg" alt="Images" />
                      </div>
                      {/* <div class="auother-text">
                        <span>Ceo Founder</span>
                        <h5 class="title">Langua M. Tim</h5>
                      </div> */}
                      <div class="testimonial-shapes">
                        <img src="assets/images/shapes/test-1.png" alt="Shapes" />
                      </div>
                    </div>
                  </div>
                  <div class="testimonial-two">
                    <div class="testimonial-images">
                      <img src="assets/images/testimonial/images-3.png" alt="Images" />
                    </div>
                    <div class="testimonial-content">
                      <div class="google-icon">
                        <img src="assets/images/icons/google.svg" alt="Icon" />
                      </div>
                      <div class="testimonial-rating">
                        <div class="star-ratings">
                          <div class="fill-ratings" style={{ width: "73%" }}>
                            <span>★★★★★</span>
                          </div>
                          <div class="empty-ratings">
                            <span>★★★★★</span>
                          </div>
                        </div>
                      </div>
                      <div class="testimonial-percent">
                        <span class="percent">4.9 / 5.0</span>
                        {/* <span class="review">(from 20k reviews)</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="testimonial-item">
                    <div class="testimonial-quote">
                      <img src="assets/images/icons/quote.svg" alt="Icon" />
                    </div>
                    <div class="desc">
                      <h3>Find your Dream Home with us</h3>
                      <p className='text-light'>
                      Can't find your dream home? Don't worry! Share your vision with us, and we'll
dedicate ourselves to finding the perfect match for you. Our expert team will listen to
your specific needs and preferences, utilizing our extensive network and market
knowledge to locate properties that align with your desires. Whether it's a luxurious
beachfront villa, a charming countryside estate, or a modern urban apartment, we are
committed to hunting down the ideal home for you. Let us help you turn your dream into
reality, ensuring a smooth and satisfying buying experience from start to finish
                      </p>
                    </div>
                    <div class="testimonial-auother">
                      <div class="auother-images">
                        <img src="assets/images/service/service-3.jpg" alt="Images" />
                      </div>
                      {/* <div class="auother-text">
                        <span>Manager </span>
                        <h5 class="title">Ramson Singhia</h5>
                      </div> */}
                      <div class="testimonial-shapes">
                        <img src="assets/images/shapes/test-1.png" alt="Shapes" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="testimonial-bg">
            <img src="assets/images/testimonial/test-bg.jpg" alt="Images" />
          </div>
        </section>
        </>
    )
}

export default OurTestimonials