import { Link } from "react-router-dom"

const NewsArticles =()=>{
    return(
        <>
         <section class="tj-blog-section">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="tj-heading-area">
                  <h2 class="title"><span><sup>02</sup> Featured Listings</span></h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                  <div class="blog-meta">
                    <ul>
                      <li class="active-text">20 Feb, 2024</li>
                      <li class="category-titlem text-white"><Link to="#">Architecture</Link></li>
                    </ul>
                  </div>
                  <div class="blog-content">
                    <h5 class="title"><Link to="#">Learn the Right to Use Wall Sheet</Link></h5>
                    <h6 class="title"><Link to="#">Price:$3432</Link></h6>

                    <div class="blog-images">
                      <Link class="blog-image" to="#"><img src="assets/images/blog/blog-1.jpg" alt="Images" /></Link>
                      <div class="blog-button bgred">
                        <Link class="tj-white-gradient-btn" to="#">Read More</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                  <div class="blog-meta">
                    <ul>
                      <li class="active-text">17 Nov, 2024</li>
                      <li class="category-title text-white"><Link to="#">Architecture</Link></li>
                    </ul>
                  </div>
                  <div class="blog-content">
                    <h5 class="title"><Link to="#">Best Equipment Sheet for and Interior</Link></h5>
                    <h6 class="title"><Link to="#">Price:$12332</Link></h6>

                    <div class="blog-images">
                      <Link class="blog-image" to="#"><img src="assets/images/blog/blog-2.jpg" alt="Images" /></Link>
                      <div class="blog-button">
                        <Link class="tj-white-gradient-btn" to="#">Read More</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                  <div class="blog-meta">
                    <ul>
                      <li class="active-text">30 June, 2024</li>
                      <li class="category-title text-white"><Link to="#">Architecture</Link></li>
                    </ul>
                  </div>
                  <div class="blog-content">
                    <h5 class="title"><Link to="#">Way to Wall Sheet Learn the Right</Link></h5>
                    <h6 class="title"><Link to="#">Price:$23432</Link></h6>

                    <div class="blog-images">
                      <Link class="blog-image" to="#"><img src="assets/images/blog/blog-3.jpg" alt="Images" /></Link>
                      <div class="blog-button">
                        <Link class="tj-white-gradient-btn" to="#">Read More</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        </>
    )
}

export default NewsArticles