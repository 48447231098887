import { Link } from "react-router-dom"

const Footer = () => {
    return (
        <>
        <footer
          className="tj-footer-area footer-1"
          data-bg-image="assets/images/bg/footer-bg.jpg"
        >
          <div className="footer-top-area">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="top-content-area">
                    <div className="footer-logo">
                      <Link to="#">
                        <img src="assets/images/logos/primary-logo.png" alt="Logo" />
                      </Link>
                    </div>
                    <div className="media-list">
                      <ul>
                        <li>
                          <Link to="#">Facebook</Link>
                        </li>
                        <li>
                          <Link to="#">Instagram</Link>
                        </li>
                        <li>
                          <Link to="#">Twitter</Link>
                        </li>
                        <li>
                          <Link to="#">Linkedin</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom-area">
            <div className="container">
              <div className="row">
                <div className="col-xl-3 col-lg-6 col-md-6">
                  <div className="footer-widget footer1-col-2 widget_nav_menu">
                    <div className="footer-title">
                      <h4 className="title">About Us</h4>
                    </div>
                    <nav>
                      <ul>
                        <li>
                          <Link to="#">About Us</Link>
                        </li>
                        <li>
                          <Link to="#">Custom Widget</Link>
                        </li>
                        <li>
                          <Link to="#">Short Codes</Link>
                        </li>
                        <li>
                          <Link to="#">Contact</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6">
                  <div className="footer-widget footer1-col-3 widget_nav_menu">
                    <div className="footer-title">
                      <h4 className="title">Recognitions</h4>
                    </div>
                    <nav>
                      <ul>
                        <li>
                          <Link to="#">Our Awards</Link>
                        </li>
                        <li>
                          <Link to="#">CSS Design</Link>
                        </li>
                        <li>
                          <Link to="#">The FWA</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6">
                  <div className="footer-widget footer1-col-3 recent-post">
                    <div className="footer-title">
                      <h4 className="title">News</h4>
                    </div>
                    <div className="post-item">
                      <div className="post-images">
                        <Link to="#">
                          <img
                            src="assets/images/project/project-2.jpg"
                            alt="Images"
                          />
                        </Link>
                      </div>
                      <div className="post-content">
                        <span className="date">03 Feb, 2024</span>
                        <h6 className="post-title">
                          <Link to="#">Learn the Right way to use Wall Sheet</Link>
                        </h6>
                      </div>
                    </div>
                    <div className="post-item">
                      <div className="post-images">
                        <Link to="#">
                          <img
                            src="assets/images/project/project-3.jpg"
                            alt="Images"
                          />
                        </Link>
                      </div>
                      <div className="post-content">
                        <span className="date">12 Jan, 2024</span>
                        <h6 className="post-title">
                          <Link to="#">way to use Wall Sheet Learn the Right</Link>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6">
                  <div className="footer-widget footer1-col-4 footer-contact">
                    <div className="footer-title">
                      <h4 className="title">Contact Us</h4>
                    </div>
                    <div className="contact-list">
                      <ul>
                        <li>
                          <span>
                            <i className="fa-regular fa-phone" />
                          </span>
                          <Link to="tel:+000(123)85511">+ 000 (123) 855 11</Link>
                        </li>
                        <li>
                          <span>
                            <i className="fa-regular fa-envelope" />
                          </span>
                          <Link
                            to="mailto:Feng Shui.com
                                 "
                          >
                            info@company.com
                          </Link>
                        </li>
                        <li>
                          <span>
                            <i className="fa-regular fa-location-dot" />
                          </span>
                          7525 14th Avenue NY
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-area">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="copyright-content">
                    <div className="desc">
                      <p>Copyright © 2024 all rights reserved.</p>
                    </div>
                    <div className="footer-menu">
                      <ul>
                        <li>
                          <Link to="#">Privacy</Link>
                        </li>
                        <li>
                          <Link to="#">Terms &amp; Conditions</Link>
                        </li>
                        <li>
                          <Link to="#">Support</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="mouseCursor cursor-outer" />
        <div className="mouseCursor cursor-inner">
          <span>Drag</span>
        </div>
      </>
      
    )
}
export default Footer                      