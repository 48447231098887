import Layout from "../Component/Layout/Layout"
import ListingDetail from "../Component/ListingDetail"

const LisingDetails=()=>{
    return(
        <>
        <Layout>
            <ListingDetail/>
        </Layout>
        </>
    )
}

export default LisingDetails