import { Link } from "react-router-dom"

const ChoosePlan =()=>{
    return(
        <>
          <section class="tj-price-section" data-bg-image="assets/images/bg/price-bg.jpg">
          <div class="container">
            <div class="row">
              <div class="tj-heading-area text-center">
                <h2 class="title"><span><sup>03</sup></span>Vacation Rental</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-8 col-lg-12">
                <div class="price-item">
                  <div class="price-percent">
                    <div class="percent"><span class="dolor">$</span>69</div>
                    <span class="month">/ Per Month</span>
                  </div>
                  {/* <div class="price-list">
                    <ul>
                      <li>Combination Drawing</li>
                      <li>Architect Hourly Rate</li>
                      <li>Construction Drawing</li>
                      <li>Selection of Material</li>
                      <li>Always plan support</li>
                    </ul>
                  </div> */}
                  <div class="price-text">
                    {/* <h3 class="title">Professional</h3> */}
                    <div class="desc">
                      <p className="text-white">At Feng Shui, we understand the allure of summer vacations and the potential they hold
for property owners. That's why we offer a comprehensive vacation rental service
designed to maximize your investment returns effortlessly.
</p>
                    </div>
                    <div class="price-button d-none d-lg-inline-flex">
                      <Link class="tj-primary-btn-two" to="#">Get Started</Link>
                    </div>
                  </div>
                  <div class="price-buttons d-lg-none d-flex">
                    <Link class="tj-primary-btn-two" to="#">Get Started</Link>
                  </div>
                  <div class="active-text">
                    <span>Popular</span>
                  </div>
                </div>
                <div class="price-item">
                  {/* <div class="price-percent">
                    <div class="percent"><span class="dolor">$</span>79</div>
                    <span class="month">/ Per Month</span>
                  </div> */}
                  {/* <div class="price-list">
                    <ul>
                      <li>Combination Drawing</li>
                      <li>Architect Hourly Rate</li>
                      <li>Construction Drawing</li>
                      <li>Selection of Material</li>
                      <li>Always plan support</li>
                    </ul>
                  </div> */}
                  <div class="price-text">
                    {/* <h3 class="title">Standard</h3> */}
                    <div class="desc">
                      <p className="text-white">Did you know that renting out your house during the summer can yield significant
profits? In high season, rental prices soar up to 400%, presenting a lucrative opportunity
for property owners to capitalize on the influx of tourists seeking temporary
accommodation. With our vacation rental service, property owners can sit back and relax
while we handle every aspect of the rental process. From finding reliable renters to
managing all check-ins, check-outs, and even cleaning services, we ensure a
hassle-free experience from start to finish. Gone are the days of worrying about
marketing your property or coordinating logistics</p>
                    </div>
                    <div class="price-button d-none d-lg-inline-flex">
                      <Link class="tj-primary-btn-two" to="#">Get Started</Link>
                    </div>
                  </div>
                  <div class="price-buttons d-lg-none d-flex">
                    <Link class="tj-primary-btn-two" to="#">Get Started</Link>
                  </div>
                  <div class="active-text">
                    <span>Popular</span>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-12">
                <div class="price-item active-price text-center">
                  <h3 class="title">Premium</h3>
                  <div class="desc">
                    <p className='text-light'> On a broader scale, architectures can also refer the planning ..</p>
                  </div>
                  <div class="price-percent">
                    <div class="percent"><span class="dolor">$</span>89</div>
                    <span class="month">/ Per Month</span>
                  </div>
                  <div>
                    <p className="text-white">With Feng Shui, property owners can enjoy passive income streams without lifting a
finger. Let us handle the hard work while you reap the rewards of a smart investment
decision.Experience the ease and profitability of vacation rental ownership with Feng
Shui. Contact us today to learn more about how we can help you maximize your
property's earning potential</p>
                  </div>
                  {/* <div class="price-list">
                    <ul>
                      <li>Architect Hourly Rate</li>
                      <li>Construction Drawing</li>
                      <li>Selection of Material</li>
                      <li>Always plan support</li>
                    </ul>
                  </div> */}
                  <div class="price-button d-flex justify-content-center">
                    <Link class="tj-primary-btn" to="#">Get Started</Link>
                  </div>
                  <div class="active-text">
                    <span>Popular</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </>
    )
}

export default ChoosePlan