import React  from 'react';
// Import Swiper React components

import HomeContent from '../Component/HomeContent';
// import OurLatestProject from '../Component/OurLatestProject';
// import PeopleMind from '../Component/PeopleMind';
import ChoosePlan from '../Component/ChoosePlan';
// import OurServices from '../Component/OurServices';
import BrandName from '../Component/BrandName';
import EasyStep from '../Component/EasyStep';
import SeeDifference from '../Component/SeeDifference';
import OurTestimonials from '../Component/OurTestimonials';
// import OurTeam from '../Component/OurTeam';
// import GetIncredible from '../Component/GetIncredible';
import GetEveryAnswer from '../Component/GetEveryAnswer';
import NewsArticles from '../Component/NewsArticles';
import Layout from '../Component/Layout/Layout';


const HomeBanner = () => {
  return (
    <>
      <Layout>

      <main id="primary" class="site-main">
        <HomeContent />
        <GetEveryAnswer />

        <NewsArticles />
        {/* <OurLatestProject /> */}

        {/* <PeopleMind /> */}

        <ChoosePlan />
        {/* <OurServices /> */}

        <BrandName />

        <EasyStep />


        <OurTestimonials />
        <SeeDifference />

        {/* <OurTeam /> */}

        {/* <GetIncredible /> */}



      </main>

      </Layout>



    </>
  )
}

export default HomeBanner;