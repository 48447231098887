import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import '../style.css';

// import required modules
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

const HomeContent=()=>{
    return(
        <>
         <section class="tj-slider-section preloader-intro" style={{ backgroundImage: `url('/assets/images/slider/slider-2.jpg')` }} >
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12">
                <Swiper
                  autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    type: 'progressbar',
                  }}
                  modules={[Autoplay, Pagination, Navigation]}

                  navigation={{
                    prevEl:".slider-prev",
                    nextEl:".slider-next"
                  }}
                  className="hero-slider"
                >
                  <SwiperSlide className='tj-slider-item bg-transparent' >
                    <div class="slider-content-area w-100 ">
                      <div class="slider-text">
                        <span class="sub-title">Welcome to Feng Shui</span>
                        <h1 class="slider-title">
                          From <span>Sketch</span> <br />
                          to Life
                        </h1>
                      </div>

                    </div></SwiperSlide>

                  <SwiperSlide className='tj-slider-item bg-transparent ' >
                    <div class="slider-content-area w-100 " >
                      <div class="slider-text">
                        <span class="sub-title">Welcome to Feng Shui</span>
                        <h1 class="slider-title">
                          From <span>Sketch</span> <br />
                          to Life
                        </h1>
                      </div>

                    </div></SwiperSlide>
                
                <div class="slider-navigation">
                              <div class="slider-prev"><img src="assets/images/icons/slider-1.png" alt="Arrow" /></div>
                              <div class="slider-next"><img src="assets/images/icons/slider-2.png" alt="Arrow" /></div>
                           </div>

                </Swiper>




                           

              </div>
            </div>
          </div>
        </section>
        </>
    )
}

export default HomeContent