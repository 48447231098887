import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeBanner from './Page/HomeBanner';
import Listing from './Page/Listing';
import LisingDetails from './Page/ListingDetail';

function App() {
  return (
    <>
      <div>
        <Router>
          <Routes>
            <Route path='/' element={<HomeBanner />} />
            <Route path='/ourlisting' element={<Listing/>} />
            <Route path='/listingdetail' element={<LisingDetails/>} />


          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
