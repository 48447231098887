import { Link } from "react-router-dom"

const OurListing =()=>{
    return(
        <>
     <main id="primary" className="site-main">
  <section className="breadcrumb-wrapper">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="breadcrumb-content-area">
            <div className="breadcrumb-heading">
              <h1 className="breadcrumb-title">Listing</h1>
            </div>
            <div className="breadcrumb-link">
              <span>
                <Link to="#">
                  <span>Home</span>
                </Link>
              </span>
              <span>
                <span>Listing</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="images_wrapper text-center">
            <div className="breadcrumb-images">
              <img src="assets/images/bg/breadcrumb-bg.jpg" alt="Images" />
            </div>
            <div className="shapes-one shake-y">
              <img src="assets/images/shapes/hero-shape1.png" alt="Shapes" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="breadcrumb-bg-shapes shake-y">
      <img src="assets/images/shapes/hero-right-group.png" alt="Shapes" />
    </div>
    <div className="breadcrumb-right-shape pulse">
      <img src="assets/images/shapes/hero-shape.png" alt="Shapes" />
    </div>
  </section>
  <section className="tj-service-section service-page">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="tj-heading-area">
            <h2 className="title mt-5">Listing</h2>
          </div>
        </div>
      </div>
      <Link to='/listingdetail'>
      <div className="row masonry-gallery">
        <div className="col-xl-3 col-lg-4 col-md-6 grid-item">
          <div className="service-item">
            <div className="service-content">
              <h5 className="service-title">
                <Link to="#">Interdisciple Entity</Link>
              </h5>
              <div className="desc">
                <p className="text-light">
                  Many architectures to services now incorporates and
                  environments friendly design ..
                </p>
              </div>
              <div className="service-icon">
                <i className="flaticon-employee" />
              </div>
              <div className="item-shapes">
                <img src="assets/images/shapes/service-1.png" alt="Shapes" />
              </div>
            </div>
            <div className="service-item-two">
              <div className="service-inner">
                <h5 className="service-title">
                  <Link to="#">Space Planning</Link>
                </h5>
                <div className="service-icon">
                  <i className="flaticon-plan" />
                </div>
                <div className="desc">
                  <p className="text-light">
                    Some architect specialize integra- tings technology design
                    ..
                  </p>
                </div>
                <div className="item-shapes">
                  <img src="assets/images/shapes/service-1.png" alt="Shapes" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 grid-item">
          <div className="service-item">
            <div className="service-content">
              <h5 className="service-title">
                <Link to="#">Commercial Building</Link>
              </h5>
              <div className="desc">
                <p className="text-light">
                  {" "}
                  Many architectures to services now incorporates and
                  environments friendly design ..
                </p>
              </div>
              <div className="service-icon">
                <i className="flaticon-apartment" />
              </div>
              <div className="item-shapes">
                <img src="assets/images/shapes/service-1.png" alt="Shapes" />
              </div>
            </div>
            <div className="service-item-two">
              <div className="service-inner">
                <h5 className="service-title">
                  <Link to="#">Home Renovation</Link>
                </h5>
                <div className="service-icon">
                  <i className="flaticon-house" />
                </div>
                <div className="desc">
                  <p className="text-light">
                    Some architect specialize integra- tings technology design
                    ..
                  </p>
                </div>
                <div className="item-shapes">
                  <img src="assets/images/shapes/service-1.png" alt="Shapes" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 grid-item">
          <div className="service-item">
            <div className="service-content">
              <h5 className="service-title">
                <Link to="#">Exterior Design</Link>
              </h5>
              <div className="desc">
                <p className="text-light">
                  Many architectures to services now incorporates and
                  environments friendly design ..
                </p>
              </div>
              <div className="service-icon">
                <i className="flaticon-house-1" />
              </div>
              <div className="item-shapes">
                <img src="assets/images/shapes/service-1.png" alt="Shapes" />
              </div>
            </div>
            <div className="service-item-two">
              <div className="service-inner">
                <h5 className="service-title">
                  <Link to="#">Architecture Design</Link>
                </h5>
                <div className="service-icon">
                  <i className="flaticon-house-design" />
                </div>
                <div className="desc">
                  <p className="text-light">
                    Some architect specialize integra- tings technology design
                    ..
                  </p>
                </div>
                <div className="item-shapes">
                  <img src="assets/images/shapes/service-1.png" alt="Shapes" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 grid-item">
          <div className="service-item">
            <div className="service-images">
              <img src="assets/images/service/service-7.jpg" alt="Images" />
            </div>
            <div className="service-button d-flex">
              <Link to="listingdetail" className="tj-primary-btn">
                View More Services
              </Link>
            </div>
          </div>
        </div>
      </div>
      </Link>
    </div>
  </section>
</main>

        </>
    )
}

export default OurListing