import { Link } from "react-router-dom"

const ListingDetail = () => {
    return (
        <>
            <main id="primary" className="site-main">
                <section className="breadcrumb-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="breadcrumb-content-area">
                                    <div className="breadcrumb-heading">
                                        <h1 className="breadcrumb-title">Listing Details</h1>
                                    </div>
                                    <div className="breadcrumb-link">
                                        <span>
                                            <Link to="#">
                                                <span>Home</span>
                                            </Link>
                                        </span>
                                        <span>
                                            <span>Listing Details</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="images_wrapper text-center">
                                    <div className="breadcrumb-images">
                                        <img src="assets/images/bg/breadcrumb-bg.jpg" alt="Images" />
                                    </div>
                                    <div className="shapes-one shake-y">
                                        <img src="assets/images/shapes/hero-shape1.png" alt="Shapes" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="breadcrumb-bg-shapes shake-y">
                        <img src="assets/images/shapes/hero-right-group.png" alt="Shapes" />
                    </div>
                    <div className="breadcrumb-right-shape pulse">
                        <img src="assets/images/shapes/hero-shape.png" alt="Shapes" />
                    </div>
                </section>
                <section className="tj-service-details">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 mt-5">
                                <div className="tj-main-sidebar">
                                    <div className="tj-service-widget">
                                        <h3 className="side-title">All Services</h3>
                                        <div className="service_catagory">
                                            <ul>
                                                <li>
                                                    <Link to="#">
                                                        Architecture
                                                        <span>
                                                            <i className="fa-thin fa-arrow-right" />
                                                        </span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        Design and Planning
                                                        <span>
                                                            <i className="fa-thin fa-arrow-right" />
                                                        </span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        Home Renovation
                                                        <span>
                                                            <i className="fa-thin fa-arrow-right" />
                                                        </span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        Exterior Design
                                                        <span>
                                                            <i className="fa-thin fa-arrow-right" />
                                                        </span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="tj-service-widget">
                                        <h3 className="side-title">Have Your Question</h3>
                                        <div className="service_form">
                                            <ul>
                                                <li>
                                                    <input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        placeholder="Name"
                                                        required=""
                                                    />
                                                    <span>
                                                        <i className="fa-light fa-user" />
                                                    </span>
                                                </li>
                                                <li>
                                                    <input
                                                        type="email"
                                                        id="emailTwo"
                                                        name="email"
                                                        placeholder="Email"
                                                        required=""
                                                    />
                                                    <span>
                                                        <i className="fa-light fa-envelope" />
                                                    </span>
                                                </li>
                                                <li>
                                                    <input
                                                        type="text"
                                                        id="tel"
                                                        name="tel"
                                                        placeholder="Phone"
                                                        required=""
                                                    />
                                                    <span>
                                                        <i className="fa-light fa-phone" />
                                                    </span>
                                                </li>
                                                <li className="text-area">
                                                    <textarea
                                                        name="text"
                                                        id="message"
                                                        placeholder="Message..."
                                                        defaultValue={""}
                                                    />
                                                    <span>
                                                        <i className="fa-light fa-paper-plane" />
                                                    </span>
                                                </li>
                                            </ul>
                                            <div className="submit-button">
                                                <button className="primary-btn">Submit Now</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tj-service-widget">
                                        <h3 className="side-title">Download</h3>
                                        <div className="service_download">
                                            <ul>
                                                <li>
                                                    <Link to="#">
                                                        Download Doc
                                                        <span>
                                                            <i className="fa-light fa-circle-down" />
                                                        </span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        Download Pdf
                                                        <span>
                                                            <i className="fa-light fa-file" />
                                                        </span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="service-details-item">
                                    <div className="service-wrapper">
                                        <div className="details-image">
                                            <img src="assets/images/service/service-5.jpg" alt="Imagesdsa" />
                                        </div>
                                        <h5 className="title">Design and Planning</h5>
                                        <div className="desc">
                                            <p className="text-light">
                                                Architects maintain communication with clients throughout the
                                                project, providing updates on progress and addressing any
                                                concerns. Architecture services are tailored to the specific
                                                needs of each project and client. flow and minimizing dard
                                                dummy text ever sinces the 1500s, when an unknown printers
                                                took the galleries and scrambled it to make a type specimen
                                                book.
                                            </p>
                                            <p className="text-light">
                                                When an unknown printer took a galley of type and scrambled it
                                                to make a type specime It has survived not only five
                                                centuries, but also the leap ques into electronic
                                                typesetting,Whether it's a residential home, commercial
                                                building,.
                                            </p>
                                        </div>
                                        <div className="check-list-one">
                                            <ul>
                                                <li className="text-light">
                                                    <span>
                                                        <i className="fa-light fa-check" />
                                                    </span>
                                                    Architects may provide services for renovating or
                                                    repurposing existing structures, adapting.
                                                </li>
                                                <li className="text-light">
                                                    <span>
                                                        <i className="fa-light fa-check" />
                                                    </span>
                                                    Some architects specialize in integrating technology into
                                                    building design, including smart home features..
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="service-video-wrapper">
                                        <div className="service-video">
                                            <img src="assets/images/service/service-6.jpg" alt="Images" />
                                            <Link
                                                className="popup-videos-button"
                                                data-autoplay="true"
                                                data-vbtype="video"
                                                to="https://www.youtube.com/watch?v=MLpWrANjFbI"
                                            >
                                                <span>
                                                    <i className="fa-solid fa-play" />
                                                </span>
                                            </Link>
                                        </div>
                                        <p className="text-light">
                                            Many architecture services now incorporate sustainable and
                                            environmentally friendly design principles, considering factors
                                            like energy to the efficiency, use of eyer arecycled materials,
                                            and eco-friendly construction methods.
                                        </p>
                                    </div>
                                    <div className="service-tabs">
                                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="service-tab nav-link active"
                                                    id="pills-home-tab"
                                                    type="button"
                                                    role="tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#service_1"
                                                    aria-selected="true"
                                                >
                                                    Specializations
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="service-tab nav-link"
                                                    id="pills-profile-tab"
                                                    type="button"
                                                    role="tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#service_2"
                                                    aria-selected="false"
                                                >
                                                    Intelligence
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="service-tab nav-link"
                                                    id="pills-contact-tab"
                                                    type="button"
                                                    role="tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#service_3"
                                                    aria-selected="false"
                                                >
                                                    Precautions
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="service-tab nav-link"
                                                    id="pills-contact-tab2"
                                                    type="button"
                                                    role="tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#service_4"
                                                    aria-selected="false"
                                                >
                                                    Super Support
                                                </button>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div
                                                className="tab-pane fade show active"
                                                id="service_1"
                                                aria-labelledby="pills-home-tab"
                                            >
                                                <div className="tabs-content">
                                                    <div className="desc">
                                                        <p className="text-light">
                                                            Each architectural creation tells a unique story,
                                                            reflecting the aspirations and values of its time. From
                                                            the grandeur of historic landmarks to the sleek lines of
                                                            modern skyscrapers, architecture weaves a narrative of
                                                            human achievement and the quest for beauty. It is a
                                                            delicate balance between form and function, where
                                                            aesthetics meet practicality in a dance of design.
                                                        </p>
                                                    </div>
                                                    <div className="tabs-list">
                                                        <ul>
                                                            <li className="text-light">
                                                                Architects work with clients to understand their
                                                                requirements, functional needs for the building.
                                                            </li>
                                                            <li className="text-light">
                                                                Some architects specialize in integrating technology
                                                                into building design, including smart home features
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="service_2"
                                                aria-labelledby="pills-profile-tab"
                                            >
                                                <div className="tabs-content">
                                                    <div className="desc">
                                                        <p className="text-light">
                                                            It is a delicate balance between form and function,
                                                            where aesthetics meet practicality in a dance of design.
                                                            Each architectural creation tells a unique story,
                                                            reflecting the aspirations and values of its time. From
                                                            the grandeur of historic landmarks to the sleek lines of
                                                            modern skyscrapers, architecture weaves a narrative of
                                                            human achievement and the quest for beauty.
                                                        </p>
                                                    </div>
                                                    <div className="tabs-list">
                                                        <ul>
                                                            <li className="text-light">
                                                                Some architects specialize in integrating technology
                                                                into building design, including smart home features
                                                            </li>
                                                            <li className="text-light">
                                                                Architects work with clients to understand their
                                                                requirements, functional needs for the building.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="service_3"
                                                aria-labelledby="pills-contact-tab"
                                            >
                                                <div className="tabs-content">
                                                    <div className="desc">
                                                        <p className="text-light">
                                                            From the grandeur of historic landmarks to the sleek
                                                            lines of modern skyscrapers, architecture weaves a
                                                            narrative of human achievement and the quest for beauty.
                                                            It is a delicate balance between form and function,
                                                            where aesthetics meet practicality in a dance of design.
                                                            Each architectural creation tells a unique story,
                                                            reflecting the aspirations and values of its time.
                                                        </p>
                                                    </div>
                                                    <div className="tabs-list">
                                                        <ul>
                                                            <li className="text-light">
                                                                Understand architects work with clients to their
                                                                requirements, functional needs for the building.
                                                            </li>
                                                            <li className="text-light">
                                                                Integrating some architects specialize in technology
                                                                into building design, including smart home features
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="service_4"
                                                aria-labelledby="pills-contact-tab2"
                                            >
                                                <div className="tabs-content">
                                                    <div className="desc">
                                                        <p className="text-light">
                                                            It is a delicate balance between form and function, Each
                                                            architectural creation tells a unique story, reflecting
                                                            the aspirations and values of its time. From the
                                                            grandeur of historic landmarks to the sleek lines of
                                                            modern skyscrapers, architecture weaves a narrative of
                                                            human achievement and the quest for beauty. where
                                                            aesthetics meet practicality in a dance of design.
                                                        </p>
                                                    </div>
                                                    <div className="tabs-list">
                                                        <ul>
                                                            <li className="text-light">
                                                                Functional architects work with clients to understand
                                                                their requirements, needs for the building.
                                                            </li>
                                                            <li className="text-light">
                                                                Including some architects specialize in integrating
                                                                technology into building design, smart home features
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="service_text">
                                        <h3 className="title">Creativity What You Want</h3>
                                        <div className="desc">
                                            <p className="text-light">
                                                Architecture is an art form that goes beyond the mere
                                                construction of buildings; it is a testament to human
                                                ingenuity, culture, and the evolving nature of society. The
                                                architect's canvas extends far beyond blueprints and
                                                structural designs; it encompasses the shaping of spaces..
                                            </p>
                                            <p className="text-light">
                                                These services encompass various stages of a building project,
                                                from conceptualization to completion. Here are some key
                                                aspects of architecture services
                                            </p>
                                        </div>
                                        <div className="check-list-one">
                                            <ul>
                                                <li className="text-light">
                                                    <span>
                                                        <i className="fa-light fa-check" />
                                                    </span>
                                                    Architects may provide services for renovating or
                                                    repurposing existing structures, adapting.
                                                </li>
                                                <li className="text-light">
                                                    <span>
                                                        <i className="fa-light fa-check" />
                                                    </span>
                                                    Some architects specialize in integrating technology into
                                                    building design, including smart home features..
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        </>
    )
}

export default ListingDetail