import { FaFacebookF } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useState } from "react";

const Header =()=>{
    const [toogle , setToogle] = useState(false);
    const [toogle2 , setToogle2] = useState(false);
    const [toogle3 , setToogle3] = useState(false);
    const [toogle4 , setToogle4] = useState(false);
    const [toogle5 , setToogle5] = useState(false);
    const [toogle6 , setToogle6] = useState(false);



     return(
<>
<div class="preloader" id="preloader">
        <div class="preloader-inner">
          <div class="lines">
            <span class="line line-1"></span>
            <span class="line line-2"></span>
            <span class="line line-3"></span>
            <span class="line line-4"></span>
            <span class="line line-5"></span>
            <span class="line line-6"></span>
            <span class="line line-7"></span>
            <span class="line line-8"></span>
            <span class="line line-9"></span>
          </div>
          <div class="text">Loading</div>
        </div>
        <button class="tj-cancel-btn">Cancel Preloader</button>
      </div>

      <div class="search_popup">
        <div class="container">
          <div class="row">
            <div class="col-xxl-12">
              <div class="search_wrapper">
                <div class="search_top d-flex justify-content-between align-items-center">
                  <div class="search_logo">
                    <Link to="/">
                      {/* <img src="assets/images/logos/primary-logo.png" alt="Logo" /> */}
                    </Link>
                  </div>
                  <div class="search_close">
                    <button type="button" class="search_close_btn">
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 1L1 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M1 1L17 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="search_form">
                  <form action="#">
                    <div class="search_input">
                      <input class="search-input-field" type="text" placeholder="" />
                      <span class="search-focus-border"></span>
                      <button type="submit">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9.55 18.1C14.272 18.1 18.1 14.272 18.1 9.55C18.1 4.82797 14.272 1 9.55 1C4.82797 1 1 4.82797 1 9.55C1 14.272 4.82797 18.1 9.55 18.1Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M19.0002 19.0002L17.2002 17.2002"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="search-popup-overlay"></div>

      <div class="progress-wrap" id="scrollUp">
        <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>

      <div class="offcanvas-area d-none d-lg-inline-block">
        <div class="offcanvas-close">
          <Link class="canva_close" to="#"
          ><span><i class="fa-light fa-xmark"></i></span>
          </Link>
        </div>
        <div class="offcanvas-wrapper">
          <div class="side-infos">
            <Link to={"/"}>
            <div class="side-logo">
              {/* <img src="assets/images/logos/primary-logo.png" alt="Logos" /> */}
            </div>
            </Link>
            <div class="desc">
              <p>
                Professional known as the architects are responsible for creating designs Sed ut perspiciatis unde omni natus voluptatem
                accusantium doloremque laudantium aperia maquep quae abillo inventore veritatis architecto
              </p>
            </div>
            <div class="socials-infos">
              <h5 class="title">Follow Us</h5>
              <div class="sidebar-socials">
                <ul>
                  <li>
                    <Link to="#"><i class="fa-brands fa-facebook-f"></i></Link>
                  </li>
                  <li>
                    <Link to="#"><i class="fa-brands fa-x-twitter"></i></Link>
                  </li>
                  <li>
                    <Link to="#"><i class="fa-brands fa-linkedin-in"></i></Link>
                  </li>
                  <li>
                    <Link to="#"><i class="fa-brands fa-youtube"></i></Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="footer-contact">
            <h4 class="side-title">Get In Touch</h4>
            <div class="contact-item">
              <div class="contact-icon">
                <span><i class="fa-regular fa-phone"></i></span>
              </div>
              <div class="contact-text">
                <span>Call anytime</span>
                <ul>
                  <li><Link class="link" to="tel:+88235(000)-9630">+ 88235 (000) - 9630</Link></li>
                </ul>
              </div>
            </div>
            <div class="contact-item">
              <div class="contact-icon">
                <span><i class="fa-regular fa-envelope"></i></span>
              </div>
              <div class="contact-text">
                <span>Send Email</span>
                <ul>
                  <li><Link class="link" to="mailto:info@company.com">info@company.com</Link></li>
                </ul>
              </div>
            </div>
            <div class="contact-item">
              <div class="contact-icon">
                <span><i class="fa-regular fa-location-dot"></i></span>
              </div>
              <div class="contact-text">
                <span>Visit Office</span>
                <ul>
                  <li>24 Division, New York</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="side-newsletter">
            <h4 class="side-title">Newsletter</h4>
            <div class="newsletter-input">
              <input type="email" id="email" name="email" placeholder="Enter your email" required="" />
              <button class="tj-secondary-btn">Subscribe Now</button>
            </div>
          </div>
        </div>
        <div class="widget_gallery popup-gallery">
          <Link class="footer-gallery-item" to="assets/images/project/project-5.jpg"
          ><img src="assets/images/project/project-5.jpg" alt="Images" />
            <div class="gallery-icon">
              <i class="fa-regular fa-plus"></i>
            </div>
          </Link>
          <Link class="footer-gallery-item" to="assets/images/project/project-6.jpg"
          ><img src="assets/images/project/project-6.jpg" alt="Images" />
            <div class="gallery-icon">
              <i class="fa-regular fa-plus"></i>
            </div>
          </Link>
          <Link class="footer-gallery-item" to="assets/images/project/project-7.jpg"
          ><img src="assets/images/project/project-7.jpg" alt="Images" />
            <div class="gallery-icon">
              <i class="fa-regular fa-plus"></i>
            </div>
          </Link>
          <Link class="footer-gallery-item" to="assets/images/project/project-8.jpg"
          ><img src="assets/images/project/project-8.jpg" alt="Images" />
            <div class="gallery-icon">
              <i class="fa-regular fa-plus"></i>
            </div>
          </Link>
          <Link class="footer-gallery-item" to="assets/images/project/project-9.jpg"
          ><img src="assets/images/project/project-9.jpg" alt="Images" />
            <div class="gallery-icon">
              <i class="fa-regular fa-plus"></i>
            </div>
          </Link>
          <Link class="footer-gallery-item" to="assets/images/project/project-10.jpg"
          ><img src="assets/images/project/project-10.jpg" alt="Images" />
            <div class="gallery-icon">
              <i class="fa-regular fa-plus"></i>
            </div>
          </Link>
        </div>
      </div>

      <div class="body-overlay"></div>
      <div class={`hamburger-area d-lg-none ${toogle ? 'opened' : ''}`}>
        <div class="hamburger_bg"></div>
        <div class="hamburger_wrapper">
          <div class="hamburger_top d-flex align-items-center justify-content-between">
            <div class="hamburger_logo">
              <Link to="#" class="mobile_logo">
                <img src="assets/images/logos/primary-logo.png" alt="Logo" />
              </Link>
            </div>
            <div class="hamburger_close" onClick={() => setToogle(!toogle)}>
              <button class="hamburger_close_btn"><i class="fa-thin fa-times"></i></button>
            </div>
          </div>
          <div class="hamburger_search">
            <form method="get" action="https://themejunction.net/html/archly/demo/#">
              <button type="submit"><i class="fal fa-search"></i></button>
              <input type="search" autocomplete="off" name="s" value="" placeholder="Search here" />
            </form>
          </div>
          <div className="hamburger_menu">
  <div className="mobile_menu mean-container">
    <div className="mean-bar">
      <Link
        to="#nav"
        className="meanmenu-reveal"
        style={{ background: "", color: "", right: 0, left: "auto" }}
      >
        <span>
          <span>
            <span />
          </span>
        </span>
      </Link>
      <nav className="mean-nav">
        <ul style={{ display: `${toogle2||toogle3||toogle4||toogle5||toogle6?"block":"none"}` }}>
          <li className="has-dropdown current-menu-item">
            <Link to="#">Our listings</Link>
            <ul className="sub-menu" style={{ display: `${toogle2?"block":"none"}` }}>
              <li className="current-menu-item">
                <Link to="ourlisting">Active listings</Link>
              </li>
              <li>
                <Link to="#"> Recent sales</Link>
              </li>
            
            </ul>
            <Link onClick={() => setToogle2(!toogle2)} className="mean-expand" to="#" style={{ fontSize: 18 }}>
              <i className="fa-light fa-angle-right" />
            </Link>
          </li>
          <li className="has-dropdown current-menu-item">
            <Link to="#">Buyers </Link>
            <ul className="sub-menu" style={{ display: `${toogle3?"block":"none"}` }}>
              <li className="current-menu-item">
                <Link to=""> Buy with us</Link>
              </li>
              <li>
                <Link to="#">Location Guide</Link>
              </li>
            
            </ul>
            <Link onClick={() => setToogle3(!toogle3)} className="mean-expand" to="#" style={{ fontSize: 18 }}>
              <i className="fa-light fa-angle-right" />
            </Link>
          </li>
          <li className="has-dropdown current-menu-item">
            <Link to="#">Sellers</Link>
            <ul className="sub-menu" style={{ display: `${toogle4?"block":"none"}` }}>
              <li className="current-menu-item">
                <Link to=""> List with us</Link>
              </li>
              <li>
                <Link to="#">Marketing</Link>
              </li>
            
            </ul>
            <Link onClick={() => setToogle4(!toogle4)} className="mean-expand" to="#" style={{ fontSize: 18 }}>
              <i className="fa-light fa-angle-right" />
            </Link>
          </li>
          <div className="site-logo">
            <Link className="logo" to="#">
              <img src="assets/images/logos/primary-logo.png" alt="Logo" />
            </Link>
          </div>
          <li className="has-dropdown current-menu-item">
            <Link to="#">Rentals</Link>
            <ul className="sub-menu" style={{ display: `${toogle5?"block":"none"}` }}>
              <li className="current-menu-item">
                <Link to="">Rent from us</Link>
              </li>
              <li>
                <Link to="#">Rent out with us</Link>
              </li>
            
            </ul>
            <Link onClick={() => setToogle5(!toogle5)} className="mean-expand" to="#" style={{ fontSize: 18 }}>
              <i className="fa-light fa-angle-right" />
            </Link>
          </li>
          <li className="has-dropdown current-menu-item">
            <Link to="#">About us</Link>
            <ul className="sub-menu" style={{ display: `${toogle6?"block":"none"}` }}>
              <li className="current-menu-item">
                <Link to="">Feng Shui</Link>
              </li>
              <li>
                <Link to="#">Agents</Link>
              </li>
            
            </ul>
            <Link onClick={() => setToogle6(!toogle6)} className="mean-expand" to="#" style={{ fontSize: 18 }}>
              <i className="fa-light fa-angle-right" />
            </Link>
          </li>
       
          <li className="mean-last">
            <Link to="#">Contact</Link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>

          <div class="mobile-contact">
            <div class="footer-title">
              <h4 class="title">Contact Us</h4>
            </div>
            <div class="contact-list">
              <ul>
                <li>
                  <span><i class="fa-regular fa-phone"></i></span>
                  <Link to="tel:+000(123)85511">+ 000 (123) 855 11</Link>
                </li>
                <li>
                  <span><i class="fa-regular fa-envelope"></i></span>
                  <Link
                    to="mailto:info@company.com
                        "
                  >info@company.com
                  </Link>
                </li>
                <li>
                  <span><i class="fa-regular fa-location-dot"></i></span>
                  7525 14th Avenue NY
                </li>
              </ul>
            </div>
          </div>
          <div class="mobile-socials">
            <ul>
            <li><FaFacebookF color="red" size={23}/></li>
                    <li><FaTwitter color="red" size={23}/></li>
                    <li><FaInstagram color="red" size={23}/></li>
            </ul>
          </div>
        </div>
      </div>

      <header class="tj-header-area header-absolute header-sticky header-1">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="mainmenu-wrapper">
                <div class="site-logo d-lg-none">
                  <Link class="logo" to="/">
                    <img src="assets/images/logos/primary-logo.png" alt="Logo" />
                  </Link>
                </div>
                <div class="header-left-infos">
                  <div class="side-menu d-none d-lg-inline-flex">
                    <button class="canva_expander">
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                  </div>
                  <div class="mobile-bar d-block d-lg-none" onClick={() => setToogle(!toogle)}>
                    <button class="canva_expander nav-menu-link menu-button">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                  </div>
                  <div class="header-media d-none d-lg-inline-flex">
                    {/* <ul>
                      <li class="header-search d-none d-lg-inline-flex">
                        <button class="search"><i class="fa-light fa-magnifying-glass"></i></button>
                      </li>
                    </ul> */}
                  </div>
                </div>
                <div class="primary-header-inner">
                  <div class="site-logo primary_logo d-lg-none">
                    <Link class="logo" to="/">
                      <img src="assets/images/logos/primary-logo.png" alt="Logo" />
                    </Link>
                  </div>
                  <div class="move_logo_wrap d-none d-lg-block">
                    <div class="site-logo">
                      <Link class="logo" to="/">
                        <img src="assets/images/logos/primary-logo.png" alt="Logo" />
                      </Link>
                    </div>
                    <div class="mainmenu main-mobile-menu d-none d-lg-inline-flex" id="main-menu">
                      <ul>
                        <li class="has-dropdown current-menu-item">
                          <Link to="#">Our listings</Link>
                          <ul class="sub-menu">
                            <li><Link to={'/ourlisting'}>Active listings</Link></li>
                            <li><Link to={'#'}>Recent sales</Link></li>
                          </ul>
                        </li>
                        {/* <li><Link to="about.html">About</Link></li> */}
                        <li class="has-dropdown">
                          <Link to="#">Buyers</Link>
                          <ul class="sub-menu">
                            <li><Link to="#">Buy with us</Link></li>
                            <li><Link to="#">Location Guide</Link></li>
                          </ul>
                        </li>
                        <li class="has-dropdown">
                          <Link to="#">Sellers</Link>
                          <ul class="sub-menu">
                            {/* <li class="has-dropdown">
                              <Link to="project.html">Projects</Link>
                              <ul class="sub-menu">
                                <li>
                                  <Link to="project.html">Projects</Link>
                                </li>
                                <li>
                                  <Link to="#">Project Details</Link>
                                </li>
                              </ul>
                            </li> */}
                            <li>
                              <Link to="#">List with us</Link>
                            </li>
                            <li>
                              <Link to="#">Marketing</Link>
                            </li>
                          </ul>
                        </li>
                        <li class="has-dropdown">
                          <Link to="#">Rentals</Link>
                          <ul class="sub-menu">
                            <li>
                              <Link to="#">Rent from us</Link>
                            </li>
                            <li>
                              <Link to="#">Rent out with us</Link>
                            </li>
                          </ul>
                        </li>

                        <li class="has-dropdown">
                          <Link to="#">About us</Link>
                          <ul class="sub-menu">
                            {/* <li class="has-dropdown">
                              <Link to="project.html">Projects</Link>
                              <ul class="sub-menu">
                                <li>
                                  <Link to="project.html">Projects</Link>
                                </li>
                                <li>
                                  <Link to="#">Project Details</Link>
                                </li>
                              </ul>
                            </li> */}
                            <li>
                              <Link to="#">Feng Shui</Link>
                            </li>
                            <li>
                              <Link to="#">Agents</Link>
                            </li>
                          </ul>
                        </li>
                        <li><Link to="#">Contact</Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="header-socials d-none d-lg-inline-flex">
                  <ul>
                    <li><FaFacebookF color="red" size={23}/></li>
                    <li><FaTwitter color="red" size={23}/></li>
                    <li><FaInstagram color="red" size={23}/></li>
    <li></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
</>
    )
}

export default Header