const EasyStep =()=>{
    return(
        <>
          <section class="tj-steps-section">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="tj-heading-area text-center">
                  <h2 class="title"><span><sup>04</sup></span>Why work with us?</h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="steps-content-area">
                  <div class="steps-item">
                    <h4 class="title">(25+) Vacation properties managed every year</h4>
                    <div class="desc">
                      {/* <p className='text-light'></p> */}
                    </div>
                    <div class="steps-number">
                      <span class="number" data-bg-image="assets/images/project/number.png">01</span>
                    </div>
                    <div class="steps-icon">
                      <i class="flaticon-urban-planning"></i>
                    </div>
                    <div class="steps-shape">
                      <img src="assets/images/shapes/steps-shape.png" alt="Shapes" />
                    </div>
                  </div>
                  <div class="steps-item">
                    <h4 class="title">(10+) Sophisticated Marketing Solutions to Elevate Profit, Find Buyers and
Renters.</h4>
                    <div class="desc">
                      {/* <p className='text-light'>Architects maintain communication with client throughout project, providing updates on progress..</p> */}
                    </div>
                    <div class="steps-number">
                      <span class="number" data-bg-image="assets/images/project/number.png">02</span>
                    </div>
                    <div class="steps-icon">
                      <i class="flaticon-brick-wall"></i>
                    </div>
                    <div class="steps-shape">
                      <img src="assets/images/shapes/steps-shape.png" alt="Shapes" />
                    </div>
                  </div>
                  <div class="steps-item">
                    <h4 class="title">(500+) Satisfied buyers, sellers, owners and tenants that worked with us.</h4>
                    <div class="desc">
                      {/* <p className='text-light'>Architects maintain communication with client throughout project, providing updates on progress..</p> */}
                    </div>
                    <div class="steps-number">
                      <span class="number" data-bg-image="assets/images/project/number.png">03</span>
                    </div>
                    <div class="steps-icon">
                      <i class="flaticon-continuous-improvement"></i>
                    </div>
                    <div class="steps-shape">
                      <img src="assets/images/shapes/steps-shape.png" alt="Shapes" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </>
    )
}

export default EasyStep