const OurTestimonialsSeller=()=>{
  return(
      <>
         <section class="tj-testimonial-section">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="tj-heading-area">
                <h2 class="title"><span><sup>06</sup></span>Sellers Trajectory</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="testimonial-content-area">
                <div class="testimonial-item">
                  <div class="testimonial-quote">
                    <img src="assets/images/icons/quote.svg" alt="Icon" />
                  </div>
                  <div class="desc">
                    {/* <h3>Path to the Perfect Property</h3> */}
                    <p className='text-light'>
                    Selling your home can be a complex process, but with us, it’s a seamless and
stress-free experience. We use sophisticated marketing strategies to ensure your
property gets maximum exposure to the right audience. Our comprehensive services
include everything you need as a seller: from staging advice, professional photography,
and targeted advertising to managing viewings and negotiations. Our goal is to find a
buyer who appreciates your home’s value and is willing to pay the right price. With our
expertise and dedication, you can trust us to handle every detail and achieve the best
outcome for your sale.
                    </p>
                  </div>
                  {/* <div class="testimonial-auother">
                    <div class="auother-images">
                      <img src="assets/images/service/service-2.jpg" alt="Images" />
                    </div>
                    <div class="auother-text">
                      <span>Ceo Founder</span>
                      <h5 class="title">Langua M. Tim</h5>
                    </div>
                    <div class="testimonial-shapes">
                      <img src="assets/images/shapes/test-1.png" alt="Shapes" />
                    </div>
                  </div> */}
                </div>
                <div class="testimonial-two">
                  <div class="testimonial-images">
                    <img src="assets/images/testimonial/images-3.png" alt="Images" />
                  </div>
                  <div class="testimonial-content">
                    <div class="google-icon">
                      <img src="assets/images/icons/google.svg" alt="Icon" />
                    </div>
                    <div class="testimonial-rating">
                      <div class="star-ratings">
                        <div class="fill-ratings" style={{ width: "73%" }}>
                          <span>★★★★★</span>
                        </div>
                        <div class="empty-ratings">
                          <span>★★★★★</span>
                        </div>
                      </div>
                    </div>
                    <div class="testimonial-percent">
                      <span class="percent">4.9 / 5.0</span>
                      {/* <span class="review">(from 20k reviews)</span> */}
                    </div>
                  </div>
                </div>
                <div class="testimonial-item">
                  <div class="testimonial-quote">
                    <img src="assets/images/icons/quote.svg" alt="Icon" />
                  </div>
                  <div class="desc">
                    <h3>Exclusive Seller Benefits :</h3>
                    <p className='text-light'>
                    Opt for an exclusive deal with us, and unlock a range of
premium marketing services designed to make your property stand out. With this
exclusive agreement, your home becomes our priority. We provide professional
photographs and virtual tours that highlight your property’s best features, attracting more
potential buyers. Our enhanced marketing efforts ensure your home receives the
attention it deserves, giving you a competitive edge in the market. Partner with us
exclusively, and experience a tailored approach that maximizes your home’s appeal and
accelerates the selling process.
                    </p>
                  </div>
                  {/* <div class="testimonial-auother">
                    <div class="auother-images">
                      <img src="assets/images/service/service-3.jpg" alt="Images" />
                    </div>
                    <div class="auother-text">
                      <span>Manager </span>
                      <h5 class="title">Ramson Singhia</h5>
                    </div>
                    <div class="testimonial-shapes">
                      <img src="assets/images/shapes/test-1.png" alt="Shapes" />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="testimonial-bg">
          <img src="assets/images/testimonial/test-bg.jpg" alt="Images" />
        </div>
      </section>
      </>
  )
}

export default OurTestimonialsSeller