import React from 'react';
// Import Swiper React components


import Layout from '../Component/Layout/Layout';
import OurListing from '../Component/OurListing';


const Listing = () => {
  return (
    <>
     <Layout>

      <OurListing/>
      </Layout>



    </>
  )
}

export default Listing;