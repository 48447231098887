const GetEveryAnswer =()=>{
    return(
        <>
            <section class="tj-faq-section">
            <div class="tj-heading-area text-center">
                  <h2 class="title pb-3 pt-5"><span><sup>01</sup>Timmy Christiaan</span></h2>
                </div>
        
          <div class="faq-top-content-area">
            <div class="container">
              <div class="row">

                {/* <h3><span>01</span></h3> */}
                <div class="col-lg-7"></div>
                <div class="col-lg-5">
                  <div class="faq_images d-lg-none">
                    <img src="assets/images/project/faq-1.jpg" alt="Images" />
                  </div>
                  <div class="faq-text">
                    {/* <h2 class="title">Timmy Christiaan</h2> */}
                    <div class="desc">
                      <p className="text-light">
                      Timmy Christiaan, CEO of Feng Shui Real Estate, is a trailblazer in the realm of modern
luxury real estate. With a background rooted in sales and a keen eye for market trends,
Timmy's journey began in the Netherlands, where he started selling investment plots by
phone at a young age.

                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="faq-images">
              <img src="assets/images/project/faq-1.jpg" alt="Images" />
            </div>
          </div>
          <div class="container-fluid p-0">
            <div class="row">
              <div class="col-12">
                <div class="faq-wrapper">
                  <div class="tj-progress-bar">
                  <p className="text-white">In 2020, Timmy made the strategic decision to relocate to Spain, recognizing the
immense potential of its real estate market. Initially focusing on holiday rentals, he
achieved remarkable success, which inspired him to expand his services to include
luxury estate sales. This seamless transition was driven by his desire to innovate and
elevate industry standards. At the core of Timmy's approach lies a commitment to
modernization and strategic marketing. Leveraging sophisticated technology, he has
revolutionized the way luxury properties are presented and marketed, ensuring
maximum exposure and attracting discerning buyers from around the globe. Timmy's
unique fusion of technology with the principles of Feng Shui sets his properties apart in a
competitive market. By incorporating these principles into the design and layout of each
estate, he creates spaces that resonate with harmony and sophistication, appealing to
the most discerning clientele.
</p>
                  </div>
                  <div class="accordion tj-faq" id="faqOne">
                    <div class="faq-item">
                     <p className="text-white">Timmy Christiaan remains specialized in vacation rentals, continuing to offer top-tier
service in this niche market. As a leader in the industry, Timmy remains dedicated to
pushing boundaries and setting new standards of excellence. With a focus on innovation
and client satisfaction, he continues to drive forward, cementing his reputation as a
visionary in the world of modern luxury real estate.</p>
                    </div>
                    {/* <div class="faq-item">
                      <span class="number">02</span>
                      <div class="faq-content">
                        <button
                          class="faq-title collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-2"
                          aria-expanded="false"
                        >
                          How to coustomized kind theme ?
                        </button>
                        <div id="faq-2" class="collapse" data-bs-parent="#faqOne">
                          <div class="accordion-body faq-text">
                            <p>
                              Architecture is an art form that goes beyond the mere construction of building; it is testament to human
                              ingenuity, culture, and the evolving nature of society.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="faq-item">
                      <span class="number">03</span>
                      <div class="faq-content">
                        <button
                          class="faq-title collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-3"
                          aria-expanded="false"
                        >
                          What is terms and conditions ?
                        </button>
                        <div id="faq-3" class="collapse" data-bs-parent="#faqOne">
                          <div class="accordion-body faq-text">
                            <p>
                              Architecture is an art form that goes beyond the mere construction of building; it is testament to human
                              ingenuity, culture, and the evolving nature of society.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="faq-sec-shapes">
            <img src="assets/images/shapes/faq-1.png" alt="Shapes" />
          </div>
        </section>
        </>
    )
}

export default GetEveryAnswer