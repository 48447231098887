const BrandName=()=>{
    return(
        <>
               <section class="tj-roll-section">
          <div class="slide-roll">
            <div class="roll-box roll-box-revers">
              <div class="roll-item">
                <span class="text">*Branding</span>
              </div>
              <div class="roll-item">
                <span class="text">*Architect</span>
              </div>
              <div class="roll-item">
                <span class="text">*Design</span>
              </div>
              <div class="roll-item">
                <span class="text">*Branding</span>
              </div>
              <div class="roll-item">
                <span class="text">*Architect</span>
              </div>
              <div class="roll-item">
                <span class="text">*Design</span>
              </div>
            </div>
            <div class="roll-box roll-box-revers">
              <div class="roll-item">
                <span class="text">*Branding</span>
              </div>
              <div class="roll-item">
                <span class="text">*Architect</span>
              </div>
              <div class="roll-item">
                <span class="text">*Design</span>
              </div>
              <div class="roll-item">
                <span class="text">*Branding</span>
              </div>
              <div class="roll-item">
                <span class="text">*Architect</span>
              </div>
              <div class="roll-item">
                <span class="text">*Design</span>
              </div>
            </div>
          </div>
        </section>
        </>
    )
}

export default BrandName